import * as React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="container">
      <div className="about-hero">
        <StaticImage
          src="../images/logo.svg"
          alt="BM Creative Works"
          className="menu-logo"
        />
      </div>
      <h1 style={{ marginTop: '150px' }}>404: Not Found</h1>
      <p>Aradığınız sayfa bulunamadı.</p>
      <Link style={{ color: 'white' }} to="/">
        Anasayfaya dön.
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
